<template>
    <div>
        <!-- 顶部 -->
        <!-- <el-row>
            <el-col>
                <el-form :inline='true'>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row> -->
        <!-- 内容 -->
        <el-tree
        :props="props"
        node-key="ID"
        ref='tree'
        check-strictly
        highlight-current
        check-on-click-node
        v-loading='listLoading'
        @check-change='handleCheckChange'
        @node-click='nodeClick'
        :data='treeData'
        :default-expand-all='false'>
            <div slot-scope="{node}" style='display:flex;width:45%;justify-content:space-between'>
                <div>
                    <i class='el-icon-notebook-1'></i>
                    <!-- <i style='font-style:normal'>{{node.label}}</i> -->
                    <i v-if="node.label.DataType == 0" style='font-style:normal;color:#FF0000!important;'>{{node.label.Name}}</i>
                    <i v-if="node.label.DataType == 1" style='font-style:normal;'>{{node.label.Name}}</i>
                    <i v-if="node.label.DataType == 2" style='font-style:normal;color:#0000FF!important;'>{{node.label.Name}}</i>
                </div>                
                <el-button size='mini' type='primary' @click='getTeachingVideoListPage(node.data.ID,node.data.DataType)'>视频管理</el-button>
            </div>
        </el-tree>
    </div>
</template>

<script>
import { getButtonList } from "../../promissionRouter";
import Toolbar from '../../components/Toolbar.vue'
import Qs from 'qs'
import {getTeachingMaterialTree} from '@/api/api';
import PlvVideoUpload from '@polyv/vod-upload-js-sdk'; // 保利威
export default {
    inject:['reload'],
    components:{Toolbar},
    data(){
        return {
            buttonList:[],
            filters:{
                parentId:'',
                keyWord:'',
                isEnable:''
            },
            options:[
                {label:'启用',value:true},
                {label:'禁用',value:false}
            ],
            props:{
                label:function(data) {
                    return {
                        DataType: data.DataType,
                        Name: data.Name
                    }
                },
                children:'Children'
            },
            listLoading:false,
            selectID:null, // 选中的教材ID
            selectData:{}, // 选中的数据对象
            node:{}, // 选中的node节点

            treeData:[], // 树数据
        }
    },
    methods:{
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 得到树数据
        getTreeData(){
            this.listLoading = true
            var params = {isEnable:true,parentId:0}
            getTeachingMaterialTree(params).then(res => {
                this.listLoading = false
                if(res.data.Success){
                    // this.$message.success(res.data.Message)
                    this.treeData = res.data.Response
                }else{
                    this.$message.error(res.data.Message)
                }
            }).catch(() => {this.listLoading = false})
        },
        handleCheckChange(data,checked){
            if(checked){
                // this.selectID = data.ID
                this.selectData = {...data}
                this.$refs['tree'].setCheckedKeys([data.ID],true)  // 设置一次只能选择一个节点
            }
        },
        nodeClick(data,node){
            if(node.checked == true){
                this.selectID = data.ID
                this.node = node
            }           
        },
        // 视频目录管理
        getTeachingVideoListPage(id,dataType){
            this.$router.push({path:'/VideoCatelogueManage',query:{id:id,dataType:dataType}})                  
        },
    },
    created(){
        this.getTreeData()
    },
    mounted(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    },   
    beforeRouteLeave(to,from,next){
        sessionStorage.setItem('isEnable','')
        next()
    }
}
</script>

<style lang="stylus" scoped>
    /deep/ .el-tree-node__label{
        font-size:16px!important;
        padding:3px 0;
    }
    /deep/ .el-tree-node__content{
        font-size:16px;
    }
    /deep/ .el-tree-node{
        padding-top:3px!important;
        padding-bottom:3px!important;
    }
</style>